<template>
    <bread-pages>
        <div class="main" v-loading="loading">
            <div class="title">{{ info.name }}</div>
            <div class="title_tip">专利公开详情</div>
            <div class="content">
                <div class="sub-title">著录项</div>
                <div class="info">
                    <div class="info-item">
                        <span class="text1">登记号：</span>
                        <span class="text2">{{ info.code }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">登记日期:</span>
                        <span class="text2">{{ info.registrationTime || '暂无' }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">{{ `${info.type == 'COPY' ? '作品类型' : '软著类型'}` }}：</span>
                        <span class="text2">{{ info.workName }}</span>
                    </div>
                    <div class="info-item" v-if="info.type == 'COPY'">
                        <span class="text1">作者：</span>
                        <span class="text2">{{ info.author }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">著作权人：</span>
                        <span class="text2">{{ info.owner }}</span>
                    </div>
                    <!-- <div class="info-item" v-else>
                        <span class="text1">著作权人：</span>
                        <span class="text2">{{ info.owner }}</span>
                    </div> -->
                    <div class="info-item">
                        <span class="text1">交易方式：</span>
                        <span class="text2">{{ getLabelName(info.tradingMethod, tradingMethodOption) }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">价格：</span>
                        <span class="text2" v-if="info.negotiateDirectly">面议 </span>
                        <span class="text2" v-else>{{ info.expectedPrice }}万元 </span>
                    </div>
                    <!-- <div class="info-item">
                        <span class="text1">底价：</span>
                        <span class="text2">{{ info.basePrice }}万元</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">佣金类型：</span>
                        <span class="text2">{{ getLabelName(info.commissionType, commissionTypeOptions) }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">佣金：</span>
                        <span class="text2">{{ info.commission }}万元</span>
                    </div> -->
                    <!-- <div class="info-item">
                        <span class="text1">联系人：</span>
                        <span class="text2">{{ info.contact }}</span>
                    </div> -->
                    <!-- <div class="info-item">
                        <span class="text1">电话：</span>
                        <span class="text2">{{ info.phone }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">邮箱：</span>
                        <span class="text2">{{ info.email }}</span>
                    </div> -->
                    <!-- <div class="info-item">
                        <span class="text1">所在地区：</span>
                        <span class="text2">{{ info.address }}</span>
                    </div> -->
                </div>
                <div class="sub-title">{{ `${info.type == 'COPY' ? '作品图样' : '软著图样'}` }}</div>
                <div class="ImgBox">
                    <el-carousel>
                        <el-carousel-item v-for="(item, index) in info.img" :key="index">
                            <el-image :src="getImg(item)" fit="fill"></el-image>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <!-- <div class="text">
                    <el-image v-for="(item, index) in info.img" :key="index" :src="item" fit="fill"></el-image>
                </div> -->
            </div>
            <div class="btn">
                <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small">
                    <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                    咨询
                </el-button>
                <el-button @click.prevent="postMessage" type="primary" class="icon-button" size="small">
                    <i class="iconfont iconfont-nav_icon_xiaoxi"></i>留言
                </el-button>
            </div>
        </div>
    </bread-pages>
</template>

<script>
import BreadPages from '../../components/page/BreadPages.vue';
import comEvent from '../../mixins/comEvent';
import {
    patentTypeOptions,
    lawStatusOptions,
    ownerTypeOptions,
    tradingMethodOption,
    commissionTypeOptions,
    copyrightType
} from '../../utils/variables';
export default {
    data() {
        return {
            info: {},
            patentTypeOptions,
            lawStatusOptions,
            ownerTypeOptions,
            tradingMethodOption,
            commissionTypeOptions,
            loading: true
        };
    },
    components: {
        BreadPages
    },
    mixins: [comEvent],
    mounted() {
        this.loading = true;
        this.$http.get('/copyright/get/' + this.$route.query.id).then(res => {
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);

            let info = copyrightType.find(item => {
                return item.value === res.type;
            });
            this.$EventBus.$emit('changePreTitle', {
                name: info.label,
                path: '/copyright',
                query: {
                    type: res.type
                }
            });
            this.$EventBus.$emit('changeTitle', info.label + '详情');
            document.title = info.label + '详情';
        });
    },
    created() {
        let addView = {
            id: this.$route.query.id,
            type: 'Copyright'
        };
        // console.log(addView);
        this.loading = true;
        this.$http.get('/all/addView/', addView).then(res => {
            console.log(res);
        });
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    padding: 0 30px 150px;
}
/deep/ .el-carousel__container {
    margin: 0 auto;
    height: 500px;
    width: 800px;
}
.title_tip {
    text-align: center;
    margin: 20px 0px 0px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #000000;
}
.appContainer {
    background: #ffffff !important;
}
/deep/ .el-image {
    width: 100%;
    height: 100%;
}
.title {
    font-size: 24px;
    font-weight: bold;
    color: @warn;
    line-height: 34px;
    text-align: center;
    padding: 50px 0 20px;
    border-bottom: 1px solid @bg;
    margin: 0 70px;
    border-bottom: 1px solid @bg;
}

.sub-title {
    font-size: 16px;
    font-weight: bold;
    color: @warn;
    line-height: 22px;
    padding: 50px 0 16px;
    .flex();
    &::before {
        content: '';
        width: 5px;
        height: 16px;
        background: @warn;
        border-radius: 3px;
        margin-right: 10px;
    }
}
.btn {
    margin-top: 173px;
    text-align: center;
    .icon-button {
        width: 134px;
        height: 36px;
        border-radius: 2px;
        border: 1px solid #01a041;
        line-height: 36px;
        background: #ffffff;
        color: #01a041;
        justify-content: center;
    }
}
.info {
    .flex();
    flex-wrap: wrap;
    .info-item {
        width: 45%;
        padding-right: 50px;
        box-sizing: border-box;
        line-height: 32px;
        margin-bottom: 8px;
        .flex();

        .text1 {
            font-size: 14px;
            color: #999999;
            display: inline-block;
            min-width: 72px;
            flex-shrink: 0;
        }

        .text2 {
            font-size: 14px;
            color: #000;
            margin-left: 6px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:nth-child(2n) {
            .text1 {
                min-width: 98px;
            }
        }
    }
}

.text {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
}
</style>
